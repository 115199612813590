import { Form, useOutletContext } from "@remix-run/react";
import { useEffect, useState } from "react";
export default function SearchForm({ theme }) {
  const { search, user } = useOutletContext();
  let serverQuery = search?.query ? search.query : "";
  let [query, setQuery] = useState(serverQuery);
  const handleSubmit = (e) => {
    e.preventDefault();
    // Programmatically submit the form here if needed or allow Remix's default form submission.
    e.target.submit(); // This ensures the form is actually submitted.
    document.getElementById("query").blur(); // Manually blur the input to dismiss the keyboard.
  };
  return (
    <div className="relative w-full">
      <Form
        method="get"
        action="/search"
        onSubmit={handleSubmit}
        className="relative w-full"
      >
        <input
          autoComplete="off"
          className={`bg-transparent w-full ${
            theme === "light"
              ? "text-black placeholder:text-black border-2 border-[#f0f0f0]"
              : "text-white placeholder:text-white"
          }  placeholder:opacity-60 h-[50px] px-5 rounded-lg w-full`}
          id="query"
          name="query"
          value={query}
          type="text"
          placeholder={
            user && user.business === "nationwide"
              ? "Search Ouri's Meats, Fish & Cheeses"
              : "Search Ouri's Market"
          }
          onChange={(e) => setQuery(e.target.value)}
        />
        <button
          className={`w-[50px] h-[50px] flex justify-center items-center absolute right-0 top-0 ${
            theme === "light" ? "text-black" : "text-white"
          }`}
          type="submit"
        >
          <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7164 11.7164L15 15"
              style={{
                strokeLinecap: "round",
                strokeWidth: "2",
                stroke: "currentcolor",
              }}
            ></path>
            <path
              d="M1.86578 7.49467C1.86578 10.6034 4.38595 13.1236 7.49473 13.1236C9.05179 13.1236 10.4613 12.4914 11.4803 11.4696C12.4959 10.4515 13.1237 9.04638 13.1237 7.49467C13.1237 4.38589 10.6035 1.86572 7.49473 1.86572C4.38595 1.86572 1.86578 4.38589 1.86578 7.49467Z"
              style={{
                strokeLinecap: "round",
                strokeWidth: "2",
                stroke: "currentcolor",
              }}
            ></path>
          </svg>
        </button>
      </Form>
    </div>
  );
}
